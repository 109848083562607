import { generateBorderStyle } from "utils/Util";

export const COMMON_INPUT_STYLE = {
  inputTextAlign: "text-left",
  inputMarginRight: "",
  inputMarginLeft: "",
  inputMarginTop: "",
  inputMarginBottom: "",
  inputFontSize: "text-body-copy-1",
  inputPaddingV: "px-0 pb-px",
  inputPaddingH: "py-0",
  inputHeight: "h-11",
  inputWidth: "w-full",
  borderStyle: " border-b focus:border-b-2 focus-within:border-b-2 active:border-b-2 focus:pb-0",
  inputBorderRadius: "",
  inputFocusRingWidth: "outline-none",
  inputFocusBackgroundColor: "focus:bg-transparent",
  inputFocusBorderColor: "focus:border-secondary-blueAzure-500",
  inputActiveBorderColor: "active:border-secondary-blueAzure-500",
  inputFocusBorderWidth: "",
  inputFocusShadow: "",
  inputHoverBackgroundColor: "hover:bg-transparent",
  inputHoverBorderColor: "hover:border-secondary-blueAzure-500",
  inputTextColor: "text-primary-pText-900",
  inputBorderColor: "border-primary-gray-300",
  inputBackgroundColor: "bg-transparent",
  inputPlaceHolderTextColor: "placeholder-primary-gray-700",
  inputFontFamily: "font-BrownRegular",
  inputBoxShadow: "",
  inputFlexAlignStyle: "items-center",
  disabled: {
    inputBorderColor: "border-secondary-disabledGrey",
    inputBackgroundColor: "bg-transparent",
    inputPlaceHolderTextColor: "placeholder-secondary-disabledGrey",
    inputTextColor: "text-secondary-disabledGrey",
  },

  error: {
    inputBorderColor: "border-primary-red-600",
    inputTextColor: "text-primary-red-600",
    inputBackgroundColor: "bg-transparent",
    inputHoverBorderColor: "hover:border-primary-red-600",
    inputFocusBorderColor: "focus:border-primary-red-600",
    inputActiveBorderColor: "active:border-primary-red-600",
  },
};

const COMMON_INPUT_BOX_STYLE = {
  ...COMMON_INPUT_STYLE,
  inputHeight: "h-9",
};
// INPUT Box Variants
export const INPUT_BOX_PRIMARY_STYLE = {
  ...COMMON_INPUT_BOX_STYLE,
};

export const INPUT_BOX_SECONDARY_STYLE = {
  ...COMMON_INPUT_BOX_STYLE,

  //Input: Color
  inputBorderColor: "border-primary-violet-600",
  inputHoverBorderColor: "hover:border-primary-gray-400",
  inputFocusBackgroundColor: "focus:bg-white",
  inputFocusColor: "outline-none",
  inputTextColor: "text-primary-gray-800",
  inputPlaceHolderTextColor: "placeholder-primary-gray-400",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor: "focus:border-primary-violet-600",
  inputBoxShadow: "",
  inputDisabledBackgroundColor: "bg-primary-gray-200",
  inputDisabledBorderColor: "border-primary-gray-500",

  // Input:Size
  inputFontSize: "text-sm",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2",
  inputHeight: "h-10",
  inputWidth: "w-100",
  inputFocusBorderWidth: "focus:border-2",
  inputTextAlign: "text-left",
};

//Styling Component incase of error.
export const INPUT_BOX_ERROR = {};

export const INPUT_BOX_TERTIARY_STYLE = {
  inputTextAlign: "text-left",
  inputMarginRight: "",
  inputMarginLeft: "",
  inputMarginTop: "",
  inputMarginBottom: "",
  inputFontSize: "text-body-copy-1",
  inputPaddingV: "px-0 pb-px",
  inputPaddingH: "py-0",
  inputHeight: "h-11",
  inputWidth: "w-full",
  borderStyle: " border border-primary-gray-300",
  inputBorderRadius: "rounded-lg",
  inputFocusRingWidth: "outline-none",
  inputFocusBackgroundColor: "focus:bg-white",
  inputFocusBorderColor: "focus:border-secondary-blueAzure-500",
  inputActiveBorderColor: "active:border-secondary-blueAzure-500",
  inputFocusBorderWidth: "focus:border-2",
  inputFocusShadow: "",
  inputHoverBackgroundColor: "hover:bg-white",
  inputHoverBorderColor: "hover:border-secondary-blueAzure-500",
  inputTextColor: "text-primary-pText-900",
  inputBorderColor: "border-primary-gray-300",
  inputBackgroundColor: "bg-white",
  inputPlaceHolderTextColor: "placeholder-primary-gray-700",
  inputFontFamily: "font-BrownRegular",
  inputBoxShadow: "",
  inputFlexAlignStyle: "items-center",
  disabled: {
    inputBorderColor: "border-secondary-disabledGrey",
    inputBackgroundColor: "bg-transparent",
    inputPlaceHolderTextColor: "placeholder-secondary-disabledGrey",
    inputTextColor: "text-secondary-disabledGrey",
  },

  error: {
    inputBorderColor: "border-primary-red-600",
    inputTextColor: "text-primary-red-600",
    inputBackgroundColor: "bg-transparent",
    inputHoverBorderColor: "hover:border-primary-red-600",
    inputFocusBorderColor: "focus:border-primary-red-600",
    inputActiveBorderColor: "active:border-primary-red-600",
  },
};

export const INPUT_BOX_STYLES = {
  primaryStyle: INPUT_BOX_PRIMARY_STYLE,
  secondaryStyle: INPUT_BOX_SECONDARY_STYLE,
  tertiaryStyle: INPUT_BOX_TERTIARY_STYLE,
};

// INPUT Number Variants
export const INPUT_NUMBER_PRIMARY_STYLE = {
  ...COMMON_INPUT_BOX_STYLE,
};

export const INPUT_NUMBER_SECONDARY_STYLE = {
  ...COMMON_INPUT_BOX_STYLE,

  //Input: Color
  inputBorderColor: "border-primary-violet-600",
  inputHoverBorderColor: "hover:border-primary-gray-400",
  inputFocusBackgroundColor: "focus:bg-white",
  inputFocusColor: "outline-none",
  inputTextColor: "text-primary-gray-800",
  inputPlaceHolderTextColor: "placeholder-primary-gray-400",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor: "focus:border-primary-violet-600",
  inputBoxShadow: "",
  inputDisabledBackgroundColor: "bg-primary-gray-200",
  inputDisabledBorderColor: "border-primary-gray-500",

  // Input:Size
  inputFontSize: "text-sm",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2",
  inputHeight: "h-10",
  inputWidth: "w-100",
  inputFocusBorderWidth: "focus:border-2",
  inputTextAlign: "text-left",
};

export const INPUT_NUMBER_TERTIARY_STYLE = {
  ...COMMON_INPUT_BOX_STYLE,
  //Input Color
  inputBorderColor: "border border-primary-gray-300",
  //Input Size
};

export const INPUT_NUMBER_STYLES = {
  primaryStyle: INPUT_NUMBER_PRIMARY_STYLE,
  secondaryStyle: INPUT_NUMBER_SECONDARY_STYLE,
  tertiaryStyle: INPUT_NUMBER_TERTIARY_STYLE,
};

// Input Phone Styles

export const INPUT_PHONE_PRIMARY_STYLE = {
  ...COMMON_INPUT_BOX_STYLE,
};

export const INPUT_PHONE_SECONDARY_STYLE = {
  ...COMMON_INPUT_BOX_STYLE,

  //Input: Color
  inputBorderColor: "border-primary-violet-600",
  inputHoverBorderColor: "hover:border-primary-gray-400",
  inputFocusBackgroundColor: "focus:bg-white",
  inputFocusColor: "outline-none",
  inputTextColor: "text-primary-gray-800",
  inputPlaceHolderTextColor: "placeholder-primary-gray-400",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor: "focus:border-primary-violet-600",
  inputBoxShadow: "",
  inputDisabledBackgroundColor: "bg-primary-gray-200",
  inputDisabledBorderColor: "border-primary-gray-500",

  // Input:Size
  inputFontSize: "text-sm",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2",
  inputHeight: "h-10",
  inputWidth: "w-100",
  inputFocusBorderWidth: "focus:border-2",
  inputTextAlign: "text-left",
};

export const INPUT_PHONE_TERTIARY_STYLE = {
  ...COMMON_INPUT_BOX_STYLE,
};

export const INPUT_PHONE_STYLES = {
  primaryStyle: INPUT_PHONE_PRIMARY_STYLE,
  secondaryStyle: INPUT_PHONE_SECONDARY_STYLE,
  tertiaryStyle: INPUT_PHONE_TERTIARY_STYLE,
};

export const COMMON_LABEL_STYLE = {
  inputLabelColor: "text-primary-gray-800",
  inputLabelFontSize: "text-body-copy-1",
  inputTextAlign: "text-left",
  inputLabelFontFamily: "font-BrownLight",
  disabledStyle: {
    inputLabelColor: "text-primary-gray-200",
  },
  errorStyle: {
    inputLabelColor: "text-primary-red-500",
  },
};

export const LABEL_STYLE_PRIMARY = {
  ...COMMON_LABEL_STYLE,
};

export const LABEL_STYLES = {
  primaryStyle: LABEL_STYLE_PRIMARY,
};

//Styling Error Text
export const COMMON_ERROR_STYLE = {
  inputErrorColor: "text-primary-red-800",
  inputErrorTextSize: "text-xs",
};

export const BASE_INPUT_TEXT_AREA_STYLE = {
  inputTextAlign: "text-left",
  inputMarginRight: "",
  inputMarginLeft: "",
  inputMarginTop: "!mt-3",
  inputMarginBottom: "",
  inputFontSize: "text-body-copy-1",
  inputPaddingV: "px-3",
  inputPaddingH: "py-3",
  inputHeight: "!h-30",
  inputOverflowY: "!overflow-y-auto",
  inputWidth: "w-full",
  borderStyle: " border",
  inputBorderRadius: "rounded-OTPBorderRadius",
  inputFocusRingWidth: "outline-none",
  inputFocusBackgroundColor: "focus:bg-transparent",
  inputFocusBorderColor: "focus:border-secondary-blueAzure-500",
  inputActiveBorderColor: "active:border-secondary-blueAzure-500",
  inputFocusBorderWidth: "",
  inputFocusShadow: "",
  inputHoverBackgroundColor: "hover:bg-transparent",
  inputHoverBorderColor: "hover:border-secondary-blueAzure-500",
  inputTextColor: "text-primary-pText-900",
  inputBorderColor: "border-primary-gray-300",
  inputBackgroundColor: "bg-transparent",
  inputPlaceHolderTextColor: "placeholder-primary-gray-700",
  inputFontFamily: "font-BrownRegular",
  inputBoxShadow: "",
  inputFlexAlignStyle: "items-center",
  disabled: {
    inputBorderColor: "border-secondary-disabledGrey",
    inputBackgroundColor: "bg-transparent",
    inputPlaceHolderTextColor: "placeholder-secondary-disabledGrey",
    inputTextColor: "text-secondary-disabledGrey",
  },

  error: {
    inputBorderColor: "border-primary-red-600",
    inputTextColor: "text-primary-red-600",
    inputBackgroundColor: "bg-transparent",
    inputHoverBorderColor: "hover:border-primary-red-600",
    inputFocusBorderColor: "focus:border-primary-red-600",
    inputActiveBorderColor: "active:border-primary-red-600",
  },
};

export const INPUT_PRIMARY_TEXT_AREA_STYLE = {
  ...BASE_INPUT_TEXT_AREA_STYLE,
};

export const INPUT_SECONDARY_TEXT_AREA_STYLE = {
  ...BASE_INPUT_TEXT_AREA_STYLE,
  inputBorderColor: "border-primary-plum-700",
};

export const TEXT_AREA_BOX_STYLES = {
  primaryStyle: INPUT_PRIMARY_TEXT_AREA_STYLE,
  secondaryStyle: INPUT_SECONDARY_TEXT_AREA_STYLE,
};

export const BASE_PANEL_STYLE = {
  inputBorder: "border-none",
  inputBorderColorPanel: "border-primary-gray-200",
  inputTextColorPanel: "text-primary-pText-900",
  inputPaddingVPanel: "",
  inputPaddingHPanel: "py-0",
  inputMarginVPanel: "my-2",
  inputBorderRadiusPanel: "rounded-lg",
  inputBackgroundColorPanel: "bg-white",
  inputFontSizePanel: "text-body-copy-1",
  inputFontFamilyPanel: "font-BrownLight",
  inputTextAlignPanel: "text-left",
  disabled: {
    inputBorderColor: "border-primary-gray-200",
    inputBackgroundColor: "bg-primary-gray-100",
    inputHoverBackgroundColor: "hover:bg-primary-gray-100",
  },
  borderStyle: generateBorderStyle({ inputBorderWidth: "border-2" }, { inputBorderDirection: "" }),
};

export const PANEL_PRIMARY_STYLE = {
  ...BASE_PANEL_STYLE,
  inputHeightPanel: "!max-h-64",
  borderStyle: generateBorderStyle(
    { inputBorderWidth: "border-none" },
    { inputBorderDirection: "" }
  ),
  inputMarginRightPanel: "mr-6",
  inputMarginLeftPanel: "",
  inputMarginTopPanel: "!mt-2",
  inputMarginBottomPanel: "",
  disabled: {
    inputBorderColor: "border-primary-gray-500",
    inputBackgroundColor: "bg-primary-gray-200",
    inputPlaceHolderTextColor: "placeholder-text-primary-gray-400",
  },
};

export const BASE_DROPDOWN_STYLE = {
  ...COMMON_INPUT_STYLE,
  inputPlaceHolderTextColor: "text-primary-gray-800",
  inputBackgroundColor: "bg-white",
  inputBorderColor: "border-transparent",
  borderStyle: generateBorderStyle(
    { inputBorderWidth: "border-transparent" },
    { inputBorderDirection: "" }
  ),
  inputWidth: "w-40",
  inputTextAlign: "text-right",
  inputTextColor: "text-primary-gray-800",
};

export const DROPDOWN_PRIMARY_STYLE = {
  ...BASE_DROPDOWN_STYLE,
  inputHoverBorderColor: "hover:border-primary-gray-800",
};

export const DROPDOWN_SECONDARY_STYLE = {
  ...BASE_DROPDOWN_STYLE,
};

export const DROPDOWN_TERTIARY_STYLE = {
  ...BASE_DROPDOWN_STYLE,
};

export const DROPDOWN_QUATERNARY_STYLE = {
  ...BASE_DROPDOWN_STYLE,
  inputBorderColor: "border-primary-gray-600",
};

export const DROP_DOWN_STYLES = {
  primaryStyle: INPUT_BOX_PRIMARY_STYLE,
  secondaryStyle: INPUT_BOX_SECONDARY_STYLE,
  tertiaryStyle: INPUT_BOX_TERTIARY_STYLE,
  quaternaryStyle: DROPDOWN_QUATERNARY_STYLE,
};

//OTPBoxStyle

export const OTP_BOX_PRIMARY_STYLE = {
  borderStyle: "border-b focus:border-b-2",
  hoverStyle: "hover:border-secondary-blueAzure-500",
  focusStyle: "focus:border-secondary-blueAzure-500",
  inputFocusBorderColor: "outline-none",
  inputTextColor: "text-primary-pText-900",
  inputFontSize: "text-xss",
};

export const OTP_BOX_SECONDARY_STYLE = {
  borderStyle: "border",
  inputBorderColor: "border-primary-gray-400",
  inputTextColor: "text-primary-gray-800",
  inputFontSize: "text-sm",
};

export const OTP_BOX_STYLES = {
  primaryStyle: OTP_BOX_PRIMARY_STYLE,
  secondaryStyle: OTP_BOX_SECONDARY_STYLE,
};

export const OTP_RESEND_PRIMARY_STYLE = {
  inputTextColor: "text-primary-navy-800",
  inputFontSize: "text-xsm",
};

export const OTP_RESEND_SECONDARY_STYLE = {
  borderStyle: "border",
  inputBorderColor: "border-primary-gray-400",
  inputTextColor: "text-primary-gray-800",
  inputFontSize: "text-sm",
};

export const OTP_RESEND_STYLES = {
  primaryStyle: OTP_RESEND_PRIMARY_STYLE,
  secondaryStyle: OTP_RESEND_SECONDARY_STYLE,
};

// Autocomplete Variants
export const BASE_AUTOCOMPLETE_STYLE = {
  ...COMMON_INPUT_STYLE,
  inputMarginRight: "!mr-0",
  inputMarginLeft: "!ml-0",
  inputMarginTop: "!mt-0",
  inputMarginBottom: "!mb-0",

  inputPlaceHolderTextColor: "placeholder-text-primary-gray-800",
  // inputBackgroundColor: "bg-white",
  inputBorderColor: "border-transparent",
  inputWidth: "w-40",
  inputTextAlign: "text-right",
  inputTextColor: "text-primary-gray-800",
  disabled: {
    inputBorderColor: "border-primary-gray-500",
    inputBackgroundColor: "bg-primary-gray-200",
    inputPlaceHolderTextColor: "placeholder-text-primary-gray-400",
  },

  error: {
    inputBorderColor: "border-primary-red-700",
    inputTextColor: "text-primary-navy-800",
    inputBackgroundColor: "bg-white",
  },
};

export const AUTOCOMPLETE_PRIMARY_STYLE = {
  ...BASE_AUTOCOMPLETE_STYLE,
  //Input: Color
  inputBorderColor: "border-primary-gray-600",
  inputHoverBorderColor: "hover:border-primary-violet-400",
  inputFocusColor: "",
  inputTextColor: "text-primary-gray-800",
  inputPlaceHolderTextColor: "placeholder-primary-gray-800",
  // inputBackgroundColor: "bg-white",
  inputFocusBorderColor: "focus:border-primary-violet-600",
  inputBoxShadow: "",
  inputDisabledBackgroundColor: "bg-primary-gray-200",
  inputDisabledBorderColor: "border-primary-gray-500",

  // Input:Size
  inputFontSize: "text-sm",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2",
  inputHeight: "h-10",
  inputWidth: "w-100",
  inputFocusBorderWidth: "focus:border-2",
  inputTextAlign: "text-left",
  inputMarginRight: "!mr-4",
  inputMarginLeft: "!ml-4",
};

export const AUTOCOMPLETE_SECONDARY_STYLE = {
  ...BASE_AUTOCOMPLETE_STYLE,
  //Input: Color
  inputBorderColor: "border-primary-violet-600",
  inputHoverBorderColor: "hover:border-primary-gray-400",
  inputFocusColor: "plum-800",
  inputTextColor: "text-primary-gray-800",
  inputPlaceHolderTextColor: "placeholder-primary-gray-400",
  inputBackgroundColor: "bg-primary-gray-200",
  inputFocusBorderColor: "focus:border-primary-violet-600",
  inputBoxShadow: "",
  inputDisabledBackgroundColor: "bg-primary-gray-200",
  inputDisabledBorderColor: "border-primary-gray-500",

  // Input:Size
  inputFontSize: "text-sm",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2",
  inputHeight: "h-10",
  inputWidth: "w-100",
  inputFocusBorderWidth: "focus:border-2",
  inputTextAlign: "text-left",
};

export const AUTOCOMPLETE_STYLES = {
  primaryStyle: AUTOCOMPLETE_PRIMARY_STYLE,
  secondaryStyle: AUTOCOMPLETE_SECONDARY_STYLE,
};

// RadioButton Variants
export const RADIO_BUTTON_PRIMARY_STYLE = {
  ...COMMON_INPUT_STYLE,
  borderStyle: "border",

  //Input: Color
  inputBorderColor: "border-secondary-greyA",
  inputHoverBorderColor: "hover:border-secondary-blueAzure-500",
  inputFocusColor: "active:outline-secondary-blueAzure-200",
  inputIconTextColor: "",
  inputPlaceHolderTextColor: "",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor:
    "active:border-secondary-greyA active:outline-secondary-blueAzure-200 active:outline-offset-0",
  inputBoxShadow: "",

  // Input:Size
  inputIconFontSize: "",
  inputPaddingV: "",
  inputPaddingH: "",
  inputHeight: "h-3.5",
  inputWidth: "w-3.5",
  inputFocusBorderWidth: "active:outline-4",
  inputTextAlign: "text-left",
  inputBorderRadius: "rounded-full",

  disabled: {
    inputBorderColor: "border-secondary-concrete pointer-events-none",
  },
};

export const RADIO_BUTTON_POTENTIAL_STYLE = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  inputBorderColor: "border-mapping-secondary-cardBackground",
  inputHoverBorderColor: "hover:border-secondary-blueAzure-500",
  inputFocusColor: "active:outline-mapping-primary-p400",
  borderStyle: "border",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor:
    "active:border-mapping-secondary-cardBackground active:outline-mapping-primary-p400 active:outline-offset-0",
  inputBoxShadow: "",
  // Input:Size
  inputIconFontSize: "",
  inputPaddingV: "",
  inputPaddingH: "",
  inputHeight: "h-3.5",
  inputWidth: "w-3.5",
  inputFocusBorderWidth: "active:outline-4",
  inputTextAlign: "text-left",
  inputBorderRadius: "rounded-full",

  disabled: {
    inputBorderColor: "border-secondary-concrete pointer-events-none",
  },
};

export const RADIO_BUTTON_USUAL_STYLE = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  inputBorderColor: "border-mapping-secondary-cardBackground",
  inputHoverBorderColor: "hover:border-secondary-blueAzure-500",
  inputFocusColor: "active:outline-mapping-primary-u400",
  borderStyle: "border",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor:
    "active:border-mapping-secondary-cardBackground active:outline-mapping-primary-u400 active:outline-offset-0",
  inputBoxShadow: "",
  // Input:Size
  inputIconFontSize: "",
  inputPaddingV: "",
  inputPaddingH: "",
  inputHeight: "h-3.5",
  inputWidth: "w-3.5",
  inputFocusBorderWidth: "active:outline-4",
  inputTextAlign: "text-left",
  inputBorderRadius: "rounded-full",

  disabled: {
    inputBorderColor: "border-secondary-concrete pointer-events-none",
  },
};

export const RADIO_BUTTON_LIMITED_STYLE = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  inputBorderColor: "border-mapping-secondary-cardBackground",
  inputHoverBorderColor: "hover:border-secondary-blueAzure-500",
  inputFocusColor: "active:outline-mapping-primary-l400",
  borderStyle: "border",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor:
    "active:border-mapping-secondary-cardBackground active:outline-mapping-primary-l400 active:outline-offset-0",
  inputBoxShadow: "",
  // Input:Size
  inputIconFontSize: "",
  inputPaddingV: "",
  inputPaddingH: "",
  inputHeight: "h-3.5",
  inputWidth: "w-3.5",
  inputFocusBorderWidth: "active:outline-4",
  inputTextAlign: "text-left",
  inputBorderRadius: "rounded-full",

  disabled: {
    inputBorderColor: "border-secondary-concrete pointer-events-none",
  },
};

export const RADIO_BUTTON_PRIMARY_STYLE_CHECKED = {
  ...COMMON_INPUT_STYLE,
  borderStyle: "border",

  //Input: Color
  inputBorderColor: "border-secondary-blueAzure-500",
  inputHoverBorderColor: "",
  inputFocusColor: "",
  inputIconTextColor: "",
  inputPlaceHolderTextColor: "",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor: "border-secondary-blueAzure-500 border-4",
  inputBoxShadow: "",

  // Input:Size
  inputIconFontSize: "",
  inputPaddingV: "",
  inputPaddingH: "",
  inputHeight: "h-3.5",
  inputWidth: "w-3.5",
  inputFocusBorderWidth: "",
  inputTextAlign: "text-left",
  inputBorderRadius: "rounded-full",

  disabled: {
    inputBorderColor: "border-secondary-blueAzure-200 pointer-events-none",
  },
};

export const RADIO_BUTTON_POTENTIAL_STYLE_CHECKED = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  inputBorderColor: "border-mapping-secondary-cardBackground",
  inputHoverBorderColor: "",
  inputFocusColor: "",
  inputIconTextColor: "",
  inputPlaceHolderTextColor: "",
  inputBackgroundColor: "bg-mapping-primary-p600",
  borderStyle: "border-b focus:border-b focus-within:border-b active:border-b focus:pb-0",
  inputHoverBackgroundColor: "!hover:bg-mapping-primary-p600",
  inputFocusBorderColor: "border border-mapping-secondary-cardBackground",
  inputBoxShadow: "",

  // Input:Size
  inputIconFontSize: "",
  inputPaddingV: "",
  inputPaddingH: "",
  inputHeight: "h-3.5",
  inputWidth: "w-3.5",
  inputFocusBorderWidth: "",
  inputTextAlign: "text-left",
  inputBorderRadius: "rounded-full",

  disabled: {
    inputBorderColor: "border-secondary-blueAzure-200 pointer-events-none",
  },
};

export const RADIO_BUTTON_USUAL_STYLE_CHECKED = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  inputBorderColor: "border-mapping-secondary-cardBackground",
  inputHoverBorderColor: "",
  inputFocusColor: "",
  inputIconTextColor: "",
  inputPlaceHolderTextColor: "",
  inputBackgroundColor: "bg-mapping-primary-u600",
  borderStyle: "border-b focus:border-b focus-within:border-b active:border-b focus:pb-0",
  inputHoverBackgroundColor: "!hover:bg-mapping-primary-u600",
  inputFocusBorderColor: "border border-mapping-secondary-cardBackground",
  inputBoxShadow: "",

  // Input:Size
  inputIconFontSize: "",
  inputPaddingV: "",
  inputPaddingH: "",
  inputHeight: "h-3.5",
  inputWidth: "w-3.5",
  inputFocusBorderWidth: "",
  inputTextAlign: "text-left",
  inputBorderRadius: "rounded-full",

  disabled: {
    inputBorderColor: "border-secondary-blueAzure-200 pointer-events-none",
  },
};

export const RADIO_BUTTON_LIMITED_STYLE_CHECKED = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  inputBorderColor: "border-mapping-secondary-cardBackground",
  inputHoverBorderColor: "",
  inputFocusColor: "",
  inputIconTextColor: "",
  inputPlaceHolderTextColor: "",
  inputBackgroundColor: "bg-mapping-primary-l600",
  borderStyle: "border-b focus:border-b focus-within:border-b active:border-b focus:pb-0",
  inputHoverBackgroundColor: "!hover:bg-mapping-primary-l600",
  inputFocusBorderColor: "border border-mapping-secondary-cardBackground",
  inputBoxShadow: "",

  // Input:Size
  inputIconFontSize: "",
  inputPaddingV: "",
  inputPaddingH: "",
  inputHeight: "h-3.5",
  inputWidth: "w-3.5",
  inputFocusBorderWidth: "",
  inputTextAlign: "text-left",
  inputBorderRadius: "rounded-full",

  disabled: {
    inputBorderColor: "border-secondary-blueAzure-200 pointer-events-none",
  },
};

export const RADIO_BUTTON_STYLES = {
  primaryStyle: RADIO_BUTTON_PRIMARY_STYLE,
  potentialStyle: RADIO_BUTTON_POTENTIAL_STYLE,
  usualStyle: RADIO_BUTTON_USUAL_STYLE,
  limitedStyle: RADIO_BUTTON_LIMITED_STYLE,
};

// Checkbox Variants
export const CHECKBOX_PRIMARY_STYLE = {
  //Input: Color
  inputBorderColor: " border border-primary-gray-300",
  inputHoverBorderColor: "hover:border-primary-gray-500",
  inputFocusColor: "",
  inputIconTextColor: "",
  inputPlaceHolderTextColor: "",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor: "focus:border-primary-blueAzure-500",
  inputBoxShadow: "",

  disabled: {
    inputBorderColor: "border border-primary-gray-300",
    inputBackgroundColor: "bg-white",
  },
  // Input:Size
  inputIconFontSize: "",
  inputPaddingV: "",
  inputPaddingH: "",
  inputHeight: "h-5.5",
  inputWidth: "w-5.5",
  inputFocusBorderWidth: "focus:border-2",
  inputTextAlign: "",
  inputBorderRadius: "rounded-lg",
};

export const CHECKBOX_SECONDARY_STYLE = {
  ...COMMON_INPUT_STYLE,

  //Input: Color
  inputBorderColor: "border-primary-gray-200",
  inputHoverBorderColor: "hover:border-primary-gray-100",
  inputFocusColor: "plum-200",
  inputIconTextColor: "text-primary-gray-400",
  inputPlaceHolderTextColor: "placeholder-primary-gray-400",
  inputBackgroundColor: "bg-white",
  inputFocusBorderColor: "focus:border-primary-violet-300",
  inputBoxShadow: "",

  // Input:Size
  inputIconFontSize: "text-sm",
  inputPaddingV: "px-2",
  inputPaddingH: "py-2",
  inputHeight: "h-10",
  inputWidth: "w-100",
  inputFocusBorderWidth: "focus:border-2",
  inputTextAlign: "text-left",
};

export const CHECKBOX_PRIMARY_STYLE_CHECKED = {
  //Input: Color
  inputBorderColor: " border border-secondary-blueAzure-500",
  inputHoverBorderColor: "hover:border-secondary-blueAzure-500",
  inputFocusColor: "",
  inputIconTextColor: "",
  inputPlaceHolderTextColor: "",
  inputBackgroundColor: "bg-secondary-blueAzure-500",
  inputFocusBorderColor: "focus:border-secondary-blueAzure-500",
  inputBoxShadow: "",

  disabled: {
    inputBorderColor: "border-primary-gray-300",
    inputBackgroundColor: "bg-primary-gray-500",
    inputPaddingH: "px-1",
  },
  // Input:Size
  inputIconFontSize: "",
  inputPaddingV: "",
  inputPaddingH: "px-0.5",
  inputHeight: "h-5.5",
  inputWidth: "w-5.5",
  inputFocusBorderWidth: "focus:border-2",
  inputTextAlign: "",
  inputBorderRadius: "rounded-lg",
};

export const CHECK_BOX_STYLES = {
  primaryStyle: CHECKBOX_PRIMARY_STYLE,
  secondaryStyle: CHECKBOX_SECONDARY_STYLE,
};

// Toast Variants

export const TOAST_COMMON_STYLE = {
  inputFocusColor: "outline-none",
  inputTextColor: "text-white",
  inputBackgroundColor: "bg-white",
  inputBoxShadow: "shadow-toast",
  // Input:Size
  inputFontSize: "text-sm",
  inputPaddingV: "px-2",
  inputPaddingH: "py-4",
  inputHeight: "",
  inputWidth: "w-73",
  inputTextAlign: "text-left",
  inputBorderRadius: "rounded-toastBorderRadius",
};

export const TOAST_PRIMARY_STYLE = {
  ...TOAST_COMMON_STYLE,
  //Input: Color
  inputBorderColor:
    "border-l-4 border-secondary-blueAzure-500 border border-b-gray-300 border-t-gray-300 border-r-gray-300",
};

export const TOAST_SECONDARY_STYLE = {
  ...TOAST_COMMON_STYLE,
  //Input: Color
  inputBorderColor:
    "border-l-4 border-secondary-blueAzure-500 border border-b-gray-300 border-t-gray-300 border-r-gray-300",
};

export const TOAST_TERTIARY_STYLE = {
  ...TOAST_COMMON_STYLE,
  //Input: Color
  inputBorderColor:
    "border-l-4 border-primary-yellow-500 border border-b-gray-300 border-t-gray-300 border-r-gray-300",
};

export const TOAST_QUATERNARY_STYLE = {
  ...TOAST_COMMON_STYLE,
  //Input: Color
  inputBorderColor:
    "border-l-4 border-primary-red-700 border border-b-gray-300 border-t-gray-300 border-r-gray-300",
};

export const TOAST_QUINARY_STYLE = {
  ...TOAST_COMMON_STYLE,
  //Input: Color
  inputTextColor: "!text-white",
  inputBackgroundColor: "bg-mapping-secondary-stepper",
  inputBorderColor:
    "border-l-4 border-primary-yellow-300 border border-b-mapping-secondary-stepper border-t-mapping-secondary-stepper border-r-mapping-secondary-stepper",
};

export const TOAST_STYLES = {
  primaryStyle: TOAST_PRIMARY_STYLE,
  secondaryStyle: TOAST_SECONDARY_STYLE,
  tertiaryStyle: TOAST_TERTIARY_STYLE,
  quaternaryStyle: TOAST_QUATERNARY_STYLE,
  quinaryStyle: TOAST_QUINARY_STYLE,
};

/*
Button Component - ALL STYLE OBJECTS ARE WRITTEN BELOW
*/

// Button default
export const BUTTON_DEFAULT = {
  //buttonBorderColor: "border-secondary-blueAzure-400",
  //buttonHoverBorderColor: "hover:border-secondary-blueAzure-400",
  //buttonActiveBorderColor: "active:border-secondary-blueAzure-400",
  // buttonActiveBackgroundColor: "active:bg-secondary-blueAzure-500",
  //buttonFocusBackgroundColor: "focus:bg-secondary-blueAzure-500",
  //buttonFocusColor: "focus:outline outline-secondary-blueAzure-400",
  //buttonTextColor: "text-white",
  // buttonBackgroundColor: "bg-secondary-blueAzure-500",
  //buttonFocusBorderColor: "",
  //buttonHoverBackgroundColor: "hover:bg-secondary-blueAzure-400",
  buttonPaddingV: "px-2.5",
  buttonPaddingH: "py-2.5",
  buttonBorderRadius: "rounded-buttonBorderRadius",
  buttonFontSize: "text-xss",
  buttonFontFamily: "font-BrownRegular",
  buttonCursor: "pointer",
  buttonWidth: "w-full",
  buttonBoxShadow: "",
  disabled: {
    buttonBorderColor: "!border-primary-gray-300",
    inputBorderColor: "!border-primary-gray-300",
    buttonBackgroundColor: "!bg-primary-gray-300",
    inputBackgroundColor: "!bg-primary-gray-300",
    buttonTextColor: "!text-secondary-disabledGrey",
  },
};

//This will be common for all buttons:
export const BUTTON_PRIMARY_SHAPE_SIZE_ALIGNMENT = {
  buttonBorderRadius: "rounded-lg",
  buttonFontSize: "text-xsm",
  buttonMarginRight: "!mr-2",
  buttonMarginLeft: "!ml-2",
  buttonMarginTop: "!mt-2",
  buttonMarginBottom: "!mb-2",
  buttonPaddingV: "px-2",
  buttonPaddingH: "py-4",
  buttonHeight: "h-10",
  buttonWidth: "w-full",
  buttonTextAlign: "text-center",
  borderStyle: generateBorderStyle({ inputBorderWidth: "border-2" }, { inputBorderDirection: "" }),
};

// PRIMARY BUTTON - All Objects below:
//Severity - PLUM/DEFAULT
export const BUTTON_PRIMARY_COLOR_PLUM = {
  buttonBorderColor: "border-secondary-blueAzure-400",
  buttonHoverBorderColor: "hover:border-secondary-blueAzure-400",
  buttonActiveBorderColor: "active:border-secondary-blueAzure-400",
  buttonActiveBackgroundColor: "active:bg-secondary-blueAzure-500",
  buttonFocusBackgroundColor: "focus:bg-secondary-blueAzure-500",
  buttonFocusColor: "focus:outline outline-secondary-blueAzure-400",
  buttonTextColor: "text-white",
  buttonBackgroundColor: "bg-secondary-blueAzure-500",
  buttonFocusBorderColor: "",
  buttonHoverBackgroundColor: "hover:bg-secondary-blueAzure-400",
  buttonBoxShadow: "",
  disabled: {
    buttonBorderColor: "border-primary-gray-300",
    inputBorderColor: "border-primary-gray-300",
    inputBackgroundColor: "bg-primary-gray-300",
  },
};

//Severity - RED
export const BUTTON_PRIMARY_COLOR_RED = {
  buttonHoverBorderColor: "hover:border-primary-gray-800",
  buttonActiveBorderColor: "active:border-primary-navy-800",
  buttonTextColor: "text-white",
  buttonFocusBorderColor: "",
  buttonBoxShadow: "",
  buttonBackgroundColor: "bg-primary-red-700",
  buttonBorderColor: "border-primary-red-700",
  buttonActiveBackgroundColor: "active:bg-primary-red-800",
  buttonFocusBackgroundColor: "focus:bg-primary-red-700",
  buttonFocusColor: "focus:outline outline-primary-red-700",
  buttonHoverBackgroundColor: "hover:bg-primary-red-500",
  disabled: {
    inputBorderColor: "border-primary-gray-500",
    inputBackgroundColor: "bg-primary-gray-500",
  },
};

//Default Primary Button - Plum
export const BUTTON_PRIMARY_PLUM = {
  ...BUTTON_PRIMARY_COLOR_PLUM,
  ...BUTTON_PRIMARY_SHAPE_SIZE_ALIGNMENT,
};

//Primary Button - Red
export const BUTTON_PRIMARY_RED = {
  ...BUTTON_PRIMARY_COLOR_RED,
  ...BUTTON_PRIMARY_SHAPE_SIZE_ALIGNMENT,
};

//Primary Button
export const BUTTON_PRIMARY = {
  default: BUTTON_PRIMARY_PLUM,
  plum: BUTTON_PRIMARY_PLUM,
  red: BUTTON_PRIMARY_RED,
};

export const BUTTON_SECONDARY_STYLE = {
  buttonBorderColor: "border-primary-plum-700",
  buttonHoverBorderColor: "hover:border-primary-plum-800",
  buttonActiveBorderColor: "active:border-primary-navy-800",
  buttonActiveBackgroundColor: "active:bg-white",
  buttonFocusBackgroundColor: "focus:white",
  buttonFocusColor: "focus:outline outline-primary-plum-700",
  buttonTextColor: "text-primary-plum-700",
  buttonBackgroundColor: "bg-white",
  buttonFocusBorderColor: "",
  buttonHoverBackgroundColor: "hover:bg-white",
  buttonBoxShadow: "",
  buttonBorderRadius: "rounded-lg",

  // Input:Size
  buttonFontSize: "text-sm",
  buttonPaddingV: "px-2",
  buttonPaddingH: "py-4",
  buttonHeight: "h-10",
  buttonWidth: "w-full",
  buttonTextAlign: "text-center",
  buttonMarginRight: "!mr-2",
  buttonMarginLeft: "!ml-2",
  buttonMarginTop: "!mt-2",
  buttonMarginBottom: "!mb-2",
};

// SECONDARY BUTTON - All Objects below:
//Severity - PLUM/DEFAULT
export const BUTTON_SECONDARY_COLOR_PLUM = {
  buttonBorderColor: "border-primary-plum-700",
  buttonHoverBorderColor: "hover:border-primary-plum-800",
  buttonActiveBorderColor: "active:border-primary-navy-800",
  buttonActiveBackgroundColor: "active:bg-white",
  buttonFocusBackgroundColor: "focus:white",
  buttonFocusColor: "focus:outline outline-primary-plum-700",
  buttonTextColor: "text-primary-plum-700",
  buttonBackgroundColor: "bg-white",
  buttonFocusBorderColor: "",
  buttonHoverBackgroundColor: "hover:bg-white",
  buttonBoxShadow: "",
};

//Severity - RED
export const BUTTON_SECONDARY_COLOR_RED = {
  buttonBorderColor: "border-primary-red-700",
  buttonHoverBorderColor: "hover:border-primary-red-800",
  buttonActiveBorderColor: "active:border-primary-navy-800",
  buttonActiveBackgroundColor: "active:bg-white",
  buttonFocusBackgroundColor: "focus:white",
  buttonFocusColor: "focus:outline outline-primary-red-700",
  buttonTextColor: "text-primary-red-700",
  buttonBackgroundColor: "bg-white",
  buttonFocusBorderColor: "",
  buttonHoverBackgroundColor: "hover:bg-white",
  buttonBoxShadow: "",
};

//Secondary Button - Plum
export const BUTTON_SECONDARY_PLUM = {
  ...BUTTON_SECONDARY_COLOR_PLUM,
  ...BUTTON_PRIMARY_SHAPE_SIZE_ALIGNMENT,
};

//Secondary Button - Red
export const BUTTON_SECONDARY_RED = {
  ...BUTTON_SECONDARY_COLOR_RED,
  ...BUTTON_PRIMARY_SHAPE_SIZE_ALIGNMENT,
};

export const BUTTON_SECONDARY = {
  default: BUTTON_SECONDARY_PLUM,
  plum: BUTTON_SECONDARY_PLUM,
  red: BUTTON_SECONDARY_RED,
};

export const BUTTON_BOX_STYLES = {
  primary: BUTTON_PRIMARY,
  secondary: BUTTON_SECONDARY,
};

// Button Variants
export const COMMON_MESSAGE_STYLE = {
  //Input: Color
  messageBorderColor: "border-primary-plum-700",
  messageHoverBorderColor: "hover:border-primary-gray-800",
  messageBackgroundColor: "bg-white",
  messageHoverBackgroundColor: "hover:bg-white",
  messageBoxShadow: "",
  messageBorderRadius: "rounded-lg",
  borderStyle: generateBorderStyle(
    { inputBorderWidth: "border-2" },
    { inputBorderDirection: "-b" }
  ),
  messageMarginRight: "!mr-2",
  messageMarginLeft: "!ml-2",
  messageMarginTop: "!mt-2",
  messageMarginBottom: "!mb-2",
  messagePaddingV: "px-2",
  messagePaddingH: "py-4",
  messageHeight: "h-10",
  messageWidth: "w-full",
};

export const DIALOG_PRIMARY_STYLE = {
  ...COMMON_MESSAGE_STYLE,
  //Input: Color
  messageBorderColor: "border-primary-plum-700",
  messageHoverBorderColor: "hover:border-primary-gray-800",
  messageBackgroundColor: "bg-white",
  messageHoverBackgroundColor: "hover:bg-white",
  messageBoxShadow: "",
  messageBorderRadius: "rounded-lg",

  messageMarginRight: "!mr-2",
  messageMarginLeft: "!ml-2",
  messageMarginTop: "!mt-2",
  messageMarginBottom: "!mb-2",
  messagePaddingV: "px-2",
  messagePaddingH: "py-4",
  messageHeight: "h-100",
  messageWidth: "w-100",
};

export const DIALOG_SECONDARY_STYLE = {
  ...COMMON_MESSAGE_STYLE,
  //Input: Color
  messageBorderColor: "border-primary-plum-700",
  messageHoverBorderColor: "hover:border-primary-gray-800",
  messageBackgroundColor: "bg-white",
  messageHoverBackgroundColor: "hover:bg-white",
  messageBoxShadow: "",
  messageBorderRadius: "rounded-lg",

  messageMarginRight: "!mr-2",
  messageMarginLeft: "!ml-2",
  messageMarginTop: "!mt-2",
  messageMarginBottom: "!mb-2",
  messagePaddingV: "px-2",
  messagePaddingH: "py-4",
  messageHeight: "h-10",
  messageWidth: "w-full",
};

export const DIALOG_BOX_STYLES = {
  primaryStyle: DIALOG_PRIMARY_STYLE,
  secondaryStyle: DIALOG_SECONDARY_STYLE,
};

// ----------------------- breadCrumb styles ----------------------- //

export const BREADCRUMB_STYLE = Object.freeze({
  previous:
    "text-secondary-blueAzure-500 hover:text-primary-pText-900 font-BrownLight text-body-copy-1 ",
  active: "text-primary-pText-900 font-BrownLight text-body-copy-1",
});

// ----------------------------- select button styles --------------------------- //

export const SELECT_BUTTON_ITEM_STYLE = Object.freeze({
  toggleSelectButtonStyle:
    "text-body-copy-1 font-BrownRegular border-b-2 border-transparent w-max hover:text-secondary-blueAzure-500",
  toggleSelectButtonStyleSecondary:
    "max-w-fit py-2.5 px-3 font-BrownLight text-body-copy-1 bg-primary-gray-50 leading-none text-black !rounded-3xl font-light",
});

// ------------------------------ menu styles --------------------------------- //

export const MENU_ITEM_STYLE = Object.freeze({
  default:
    "h-9 flex items-center !mb-0 cursor-pointer hover:bg-secondary-blueAzure-500 hover:text-white",
});

// ---------------------- Progress Bar Styles ----------------------- //

export const PROGRESS_BAR_PRIMARY_STYLE = {
  progressPaddingV: "px-0",
  progressPaddingH: "py-0",
  progressHeight: "h-1.5",
  progressWidth: "w-full",
  progressBorderRadius: "rounded-inputBorderRadius",
  progressBackgroundColor: "bg-primary-gray-200",
};

export const PROGRESS_BAR_SECONDARY_STYLE = {};

export const PROGRESS_BAR_STYLES = {
  primaryStyle: PROGRESS_BAR_PRIMARY_STYLE,
  secondaryStyle: PROGRESS_BAR_SECONDARY_STYLE,
};

// ---------------------- Progress Bar Styles ----------------------- //
export const SLIDER_PRIMARY_STYLE = {
  progressHeight: "h-px",
  progressWidth: "w-full",

  progressBackgroundColor: "bg-primary-gray-400",
};

export const SLIDER_SECONDARY_STYLE = {};

export const SLIDER_STYLES = {
  primaryStyle: SLIDER_PRIMARY_STYLE,
  secondaryStyle: SLIDER_SECONDARY_STYLE,
};
