import ImageIcon from "assets/img/admin/ImageIcon.svg";
import VideoIcon from "assets/img/admin/VideoIcon.svg";
import InfoIcon from "assets/img/common/Info_Logo.svg";
import InputError from "components/InputError";
import Label from "components/Label/Label";
import TertiaryButton from "components/TertiaryButton/TertiaryButton";
import Text from "components/Text/Text";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import { FileUpload } from "primereact/fileupload";
import { Component } from "react";
import deleteIcon from "assets/img/common/deleteIcon.svg";
import deleteIconHover from "assets/img/common/deleteIcon_hover.svg";

interface FileUploadProps {
  uploadHandler?: any;
  infoText?: string;
  accept?: string;
  hideLabel?: boolean;
  label?: string;
  labelClassNames?: string;
  videoUrlOnLoad?: string;
  uploadType?: string;
  onSelect?: any;
  buttonVariant?: string;
  imageSrcOnLoad?: any;
  signedUrlOnLoad?: any;
  className?: string;
  headerClassName?: string;
  hideErrorRow?: boolean;
  validation?: any;
  errorClassNames?: any;
  onClear?: any;
}

interface IStates {
  isFileSelected?: boolean;
  totalSize?: any;
}

class ExtendedComponent extends Component<FileUploadProps, IStates> {
  fileUploadRef?: any;
}

export class FileUploadDemo extends ExtendedComponent {
  constructor(props: any) {
    super(props);

    this.state = {
      totalSize: 0,
      isFileSelected: false,
    };

    this.headerTemplate = this.headerTemplate.bind(this);
    this.itemTemplate = this.itemTemplate.bind(this);
    this.emptyTemplate = this.emptyTemplate.bind(this);
  }

  headerTemplate(options: any) {
    const { className, chooseButton, uploadButton, cancelButton } = options;

    return (
      <div className={className}>
        <div className="flex ">
          <img src={InfoIcon} alt="Information logo" />
          <Text
            label={this.props.infoText}
            className="text-secondary-greyA font-BrownLight ml-2 text-xxs"
          />
        </div>

        <div className="flex mt-4">
          {chooseButton}
          {this.props.buttonVariant !== "chooseOnly" && uploadButton}
          {this.state.isFileSelected && cancelButton}
          {!this.state.isFileSelected && (
            <div className="w-40">
              <TertiaryButton
                className="text-primary-gray-700"
                label={
                  this.props.uploadType === "video"
                    ? "Remove Video"
                    : this.props.uploadType === "audio"
                    ? "Remove Audio"
                    : "Remove Image"
                }
                icon={
                  <div>
                    <img src={deleteIcon} alt="delete-icon" />
                  </div>
                }
                iconHover={
                  <div>
                    <img src={deleteIconHover} alt="delete-icon" />
                  </div>
                }
                onClick={this.props.onClear}
                disabled={
                  this.props.uploadType === "video"
                    ? this.props.videoUrlOnLoad === ""
                    : this.props.uploadType === "audio"
                    ? this.props.videoUrlOnLoad === ""
                    : this.props.imageSrcOnLoad === ""
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  itemTemplate(file: any, props: any) {
    return (
      <div className={`flex h-60 w-full my-4 ml-4 relative ${this.props.className}`}>
        <div className="w-full h-full rounded-md text-sm flex items-center justify-center">
          {this.props.signedUrlOnLoad ? (
            <VideoPlayer
              className="video-upload-container"
              mediaSource={this.props.signedUrlOnLoad}
              onVideoComplete={{}}
            />
          ) : (
            <img
              alt={file.name}
              role="presentation"
              src={file.objectURL}
              className="w-full h-full object-cover rounded-md"
            />
          )}
        </div>
        {this.props.signedUrlOnLoad ? (
          <></>
        ) : (
          <div className="w-12 h-12 bg-white opacity-70 rounded-full p-3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <img
              src={this.props.uploadType === "video" ? VideoIcon : ImageIcon}
              alt="Information logo"
              className="w-6 h-6 "
            />
          </div>
        )}
      </div>
    );
  }

  emptyTemplate() {
    return (
      <div className={`flex h-60 w-full my-4 ml-4 relative ` + this.props.className}>
        {this.props.imageSrcOnLoad && (
          <div className="w-full h-full rounded-md text-sm flex items-center justify-center">
            <img
              alt="Uploaded_image"
              role="presentation"
              src={this.props.imageSrcOnLoad}
              className="w-full h-full object-cover rounded-md"
            />
          </div>
        )}

        {this.props.videoUrlOnLoad && (
          <div className="w-full rounded-lg overflow-hidden">
            <VideoPlayer
              className="video-upload-container"
              mediaSource={this.props.videoUrlOnLoad}
              onVideoComplete={{}}
            />
          </div>
        )}

        {this.props.videoUrlOnLoad ? (
          <></>
        ) : (
          <div className="w-12 h-12 bg-white opacity-70 rounded-full p-3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <img
              src={this.props.uploadType === "video" ? VideoIcon : ImageIcon}
              alt="Information logo"
              className="w-6 h-6 "
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const chooseOptions = {
      className:
        "custom-choose-btn p-button-rounded p-button-outlined px-1 rounded-buttonBorderRadius text-xss font-BrownRegular pointer w-36 h-9  bg-white border border-secondary-blueAzure-500 text-secondary-blueAzure-500 hover:bg-secondary-blueAzure-400  hover:border-secondary-blueAzure-400 hover:text-white focus:bg-white focus:shadow-buttonOutlinedBorder active:bg-white active:shadow-buttonOutlinedBorder focus:text-secondary-blueAzure-500 active:text-secondary-blueAzure-500 px-0 ",
      label:
        this.props.uploadType === "video"
          ? "Upload Video"
          : this.props.uploadType === "audio"
          ? "Upload Audio"
          : "Upload Image",
      icon: "pi ",
    };
    const uploadOptions = {
      icon: "pi ",
      label: this.props.uploadType === "video" ? "Upload Video" : "Upload Image",
      className:
        "custom-choose-btn p-button-rounded p-button-outlined px-1 rounded-buttonBorderRadius text-xss font-BrownRegular pointer w-36 h-9  bg-white border border-secondary-blueAzure-500 text-secondary-blueAzure-500 hover:bg-secondary-blueAzure-400  hover:border-secondary-blueAzure-400 hover:text-white focus:bg-white focus:shadow-buttonOutlinedBorder active:bg-white active:shadow-buttonOutlinedBorder focus:text-secondary-blueAzure-500 active:text-secondary-blueAzure-500 px-0 !ml-4 disabled:border-primary-gray-300 disabled:text-secondary-disabledGrey",
    };
    const cancelOptions = {
      icon: "pi pi-trash",
      label:
        this.props.uploadType === "video"
          ? "Remove Video"
          : this.props.uploadType === "audio"
          ? "Remove Audio"
          : "Remove Image",
      className:
        "p-button p-component  px-2.5 py-2.5 rounded-buttonBorderRadius text-xss font-BrownRegular w-36 h-9 pointer  border-none bg-none text-primary-gray-700 disabled:text-secondary-disabledGrey hover:text-primary-pText-900 !font-BrownLight px-0 pl-2 !ml-4",
    };

    return (
      <div className="w-full">
        {!this.props.hideLabel && (
          <Label
            label={this.props.label}
            labelClassNames={"text-primary-gray-800 text-body-copy-1 text-left font-BrownLight"}
          />
        )}
        <div className="card bg-white">
          <FileUpload
            name="demo[]"
            //url="https://intelligentlife.web-devapp.com/storage/api/awsS3/false/foundationImage/upload"
            multiple={false}
            accept={this.props.accept}
            //maxFileSize={1000000}
            headerTemplate={this.headerTemplate}
            itemTemplate={this.itemTemplate}
            emptyTemplate={this.emptyTemplate}
            className="flex flex-col-reverse"
            headerClassName={this.props.headerClassName + "ml-4"}
            ref={(el) => (this.fileUploadRef = el)}
            uploadHandler={this.props.uploadHandler}
            chooseOptions={chooseOptions}
            uploadOptions={uploadOptions}
            cancelOptions={cancelOptions}
            onSelect={this.props.onSelect}
            onClear={this.props.onClear}
            customUpload
            onBeforeSelect={() => {
              this.setState({
                isFileSelected: true,
              });
            }}
          />
        </div>
        {!this.props.hideErrorRow && (
          <InputError
            validation={this.props.validation}
            hideErrorRow={this.props.hideErrorRow}
            errorClassNames={this.props.errorClassNames}
          />
        )}
      </div>
    );
  }
}
