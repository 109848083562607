export const LOCAL_STORAGE = {
  USER_INFO: "userInfo",
  TENANT_KEY: "tenantKey",
  PLAN_NAME: "planName",
};
export const LANGUAGES = {
  EN: "en",
  FR: "fr",
};
export const LOCALE = {
  IN: "en-IN",
  US: "en-US",
};
export const DEFAULT_LOCALE_CONST = {
  LANG: "defaultLanguage",
  CURRENCY: "defaultCurrency",
  LOCALE: "defaultLocale",
};
export const ROUNDING_MODE = {
  ROUND: Math.round,
  FLOOR: Math.floor,
  CEIL: Math.ceil,
};

export const USER_TYPE = {
  TENANT_USER: "TENANT_USER",
  TENANT_ADMIN: "TENANT_ADMIN",
  PLATFORM_ADMIN: "PLATFORM_ADMIN",
};

export const INPUT_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};

export const INPUT_NUMBER_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};

export const AUTOCOMPLETE_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};

export const OTP_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};

export const LABEL_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  RIGHT: "RIGHT",
};

export const DROPDOWN_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  TERTIARY: "TERTIARY",
  QUATERNARY: "QUATERNARY",
};

export const INPUT_ERROR_TYPE = {
  BORDER: "BORDER",
  DEFAULT: "DEFAULT",
};

export const LIMIT_TYPE = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
};

export const CHECKBOX_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};

export const TOAST_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  TERTIARY: "TERTIARY",
  QUATERNARY: "QUATERNARY",
  QUINARY: "QUINARY",
};

export const BUTTON_STYLE_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};

export const COLOR_STYLE_TYPE = {
  RED: "RED",
  GRAY: "GRAY",
  PLUM: "PLUM",
};

export const LOADER_POSITION = {
  RIGHT: "right",
  LEFT: "left",
};

export const MIME_TYPE = {
  JSON: "application/json",
  PLAIN: "text/plain",
};

export const LOGIN_TYPE = Object.freeze({
  INDIVIDUAL: "INDIVIDUAL",
  ORGANISATION: "ORGANISATION",
});

export const HEAR_ABOUT_IL = Object.freeze({
  FROM: "hearAboutIL",
  OTHER: "specifyOther",
});

export const SETUP_PROFILE_RADIO_OPTIONS = Object.freeze({
  FRIENDS_COLLEAGUES: "FRIENDS_OR_COLLEAGUES",
  IL_WEBSITE: "INTELLIGENT_LIFE_WEBSITE",
  SOCIAL_MEDIA: "SOCIAL_MEDIA",
  OTHER: "OTHER",
});

export const ROLES = Object.freeze({
  ADMIN: "ADMIN",
  ADMIN_AND_LEARNER: "ADMIN & LEARNER",
  LEARNER: "LEARNER",
});

export const GRAPH_STATUS = Object.freeze({
  LOCKED: "Locked",
  IN_PROGRESS: "Progress",
  COMPLETED: "Completed",
});

export const ROLE_IN_ORG_LIST = [
  { label: "Senior Management", value: "SENIOR_MANAGEMENT" },
  { label: "Team Management", value: "TEAM_MANAGEMENT" },
  { label: "HR", value: "HR" },
  { label: "Individual Staff Member", value: "INDIVIDUAL_STAFF_MEMBER" },
];

export const ROLE_LIST = [
  { label: "Admin", value: ROLES.ADMIN, disabled: false },
  { label: "Admin & Learner", value: ROLES.ADMIN_AND_LEARNER, disabled: false },
  { label: "Learner", value: ROLES.LEARNER, disabled: false },
];

export const TOPIC_CARD_BUTTON_STATUS = Object.freeze({
  BEGIN_TOPIC: "Begin topic",
  RESUME_TOPIC: "Resume topic",
  REPEAT_TOPIC: "Repeat topic",
  LOCK_TOPIC: "Locked",
});

export const ACCORDION_BADGE_VALUE = Object.freeze({
  PAST: "Past",
  UPCOMING: "Upcoming",
});

export const PANEL_CARD_BADGE_VALUE = Object.freeze({
  INCLUDED: "Included",
  ADDED_TO_MY_STUDY: "Added to my study",
  ADDED_TO_CART: "Added to cart",
});

export const AUTH_ACTION_TYPE = {
  TENANT_SIGNUP: "TENANT_SIGNUP",
  USER_SIGNUP: "USER_SIGNUP",
  PLATFORM_ADMIN: "PLATFORM_ADMIN",
  USER_INVITE: "USER_INVITE",
  RESET_PASSWORD: "RESET_PASSWORD",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  JOIN_TENANT: "JOIN_TENANT",
};

export const CATEGORY_NAME = {
  TUTORIAL_ARCHIVE: "Tutorial Archive",
  COURSES: "Courses",
  EVENTS: "Events",
  MEDITATIONS: "Meditations",
  ARTICLES: "Articles",
  PODCASTS: "Podcasts",
};

export const CATEGORY_VALUE = {
  TUTORIAL_ARCHIVE: "tutorial archive",
  COURSES: "course",
  EVENTS: "events",
  MEDITATIONS: "meditations",
  ARTICLES: "articles",
  PODCASTS: "podcasts",
  INTERVIEWS: "interviews",
};

export const ERROR_CODE = {
  USER_VERIFICATION_PENDING: "E001: User Verification Pending",
  USER_EXIST: "E002: User Already Exist",
  USER_NOT_FOUND: "E003: User Not Found",
  TENANT_NOT_FOUND: "E004: Tenant Not Found",
  TENANT_EXIST: "E005: Tenant Already Exist",
  USER_INCORRECT: "E013: User Name Or Password Is Incorrect",
};

export const TUTORIAL_SESSION_CATEGORY = {
  ALL: "All",
  UPCOMING: "Upcoming",
  PAST: "Past",
};

export const MY_STUDY_CATEGORIES = {
  ALL: "All",
  TO_DO: "To do",
  IN_PROGRESS: "In progress",
  COMPLETED: "Completed",
};

export const MY_STUDY_CATEGORIES_VALUES = {
  ALL: "isInMyStudy", // for all the items in My Study isInMyStudy=true is used
  TO_DO: "toDo",
  IN_PROGRESS: "inProgress",
  COMPLETED: "completed",
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: "active",
  INITIATED: "initiated",
  CANCELLED: "cancelled",
};

export const ON_BOARD_STAGE = {
  CREATED: "CREATED",
  SETUP_PROFILE: "SETUP_PROFILE",
  INVITE_TEAM: "INVITE_TEAM",
  OVERVIEW: "OVERVIEW",
  COMPLETED: "COMPLETED",
};

export const VIDEO_TYPES = {
  FOUNDATION: "Foundation",
  MODULE: "Module",
  MY_STUDY: "My Study",
  DASHBOARD: "Dashboard",
  MAPPING: "Mapping",
};

export const VIDEO_SUB_TYPES = {
  MAPPING_OVERVIEW: "Overview",
};

export const LEARNING_TYPE = {
  FOUNDATION: "Foundation",
};

export const LEARNER_TYPE = [
  { label: "Organisation", value: "ORGANIZATION" },
  { label: "Individual", value: "INDIVIDUAL" },
];

export const USER_TYPE_PORTAL_ADMIN = [
  { label: "Organisation", value: "organization" },
  { label: "Individual", value: "individual" },
];

export const TRIAL_PERIOD_MONTH = [
  { label: "1 month", value: "1month" },
  { label: "3 months", value: "3months" },
];

export enum TrialPeriodDuration {
  ONE_MONTH = "30_days",
  THREE_MONTHS = "90_days",
  PAID = "PAID",
  FREE = "free",
}

export const STATUS_MAPPINGS = {
  invited: "Invited",
  used: "Registration Error",
  accepted: "Accepted",
  expired: "Expired",
};

export enum InviteStatus {
  INVITED = "invited",
  USED = "used",
  ACCEPTED = "accepted",
  Expired = "expired",
}

export const LEARNING_SECTION = {
  CONCEPT: "CONCEPT",
  PRACTICE: "PRACTICE",
  APPLICATION: "APPLICATION",
};
export const GROUP = {
  groupL0Code: "foundation",
};

export const GROUP_API = {
  FOUNDATION: "Foundation",
  MODULE: "Module",
};

export const CARD_STATE = {
  FULL_COMPLETE: "full_complete",
  PARTIAL_COMPLETE: "partial_complete",
  REPEAT_TOPIC: "repeat_topic",
  CONTINUE_LEARNING: "continue_learning",
  MODULES_IN_PROGRESS: "modules_in_progress",
  PERFORM_MAPPING: "perform_mapping",
  EMPTY: "empty",
};

export const ERROR_MESSAGE = {
  FOUNDATION: {
    BEGIN: "label.error.Begin",
  },
  LEARNING: {
    GUIDED_VIDEO: "label.error.guidedCompletion",
    TOPIC_DETAILS: "label.error.learning.topicDetail",
    TOPIC_START: "label.error.learning.topicStarted",
    CONCEPT_START: "label.error.learning.conceptStart",
    PRACTICE_START: "label.error.learning.practiceStart",
    APPLICATION_START: "label.error.learning.applicationStart",
    FETCH_QUESTION: "label.error.learning.fetchQuestion",
    SUBMIT_ANSWER: "label.error.learning.selfReflection",
  },
  CART: {
    REMOVE_CART: "label.error.cart.removeItem",
  },
  CHECKOUT: {
    CREATE_ORDER: "label.error.checkout.createOrder",
    ORDER_SUMMARY: "label.error.checkout.orderSummary",
    ORDER_SUBMIT: "label.error.checkout.orderSubmit",
  },
};

export const TOAST_MESSAGE = {
  LIBRARY_CARD_DETAILS: {
    SESSION_CARD_TOAST: "label.text.libraryCardDetail.toast.sessionAddedToCartIndividual",
    SESSIONS_CART_CARD_TOAST: "label.text.libraryCardDetail.toast.sessionAddedToCart",
    ITEMS_CART_CARD_TOAST: "label.text.libraryCardDetail.toast.itemsAddedToCart",
    ADDED_TO_MY_STUDY: "label.text.libraryCardDetail.toast.itemsAddedToStudy",
    INDIVIDUAL_TOPIC_ADDED_TO_MY_STUDY: "label.text.libraryCardDetail.toast.itemsAddedToStudy",
  },
};

export const GROUP_L0_CODE = {
  FOUNDATION: "Foundation",
  MODULE: "Module",
  LIBRARY: "library",
};

export const DASHBOARD_MENU_ITEMS = {
  DASHBOARD: "Dashboard",
  FOUNDATION: "Foundation",
  MODULE: "Module",
  TUTORIALS: "Tutorials",
  LIBRARY: "Library",
  MY_STUDY: "My study",
  MY_ANALYTICS: "My analytics",
  TEAM: "Team",
  TEAM_ANALYTICS: "Team analytics",
};

export const PLAN_TYPE = {
  INDIVIDUAL: "individual",
  ORGANIZATION: "organization",
};

export const PLAN_NAME = {
  INDIVIDUAL_FULL_PLAN: "individual full plan",
  INDIVIDUAL_BASIC_PLAN: "individual basic plan",
  ORGANIZATION_PLAN: "organization plan",
};

export const PLAN_NAME1 = {
  INDIVIDUAL_FULL_PLAN: "individual full plan",
  INDIVIDUAL_BASIC_PLAN: "individual basic plan",
  ORGANIZATION_PLAN: "organisation plan",
};

export const PLAN_NAME2 = {
  INDIVIDUAL_FULL_PLAN: "full plan",
  INDIVIDUAL_BASIC_PLAN: "basic plan",
  ORGANIZATION_PLAN: "organisation plan",
};

export const FEATURE_NAME = {
  ORGANISATION: [["Mapping"], ["Foundation"], ["Modules"], ["Library content"]],
  BASIC_PLAN: [["Mapping"], ["Foundation"]],
};

export const SECTION_NAMES = {
  APPLICATION: "application",
  CONCEPT: "concept",
  PRACTICE: "practice",
};
export const SECTION_PRICE = {
  PAID: "paid",
  FREE: "free",
};
export const TEAM_ANALYTICS_SCORE_BAND = [
  { label: "Low", value: "LOW" },
  { label: "Medium", value: "MEDIUM" },
  { label: "High", value: "HIGH" },
];
export const ORDER_TYPE = {
  CHECKOUT: "checkout",
  SUBSCRIPTION: "subscription",
};

export const CARD_STATE_DASHBOARD = {
  CONTINUE_LEARNING: "continue_learning",
  MODULES_IN_PROGRESS: "modules_in_progress",
  FOUNDATION_IN_PROGRESS: "foundation_in_progress",
  BEGIN_FOUNDATION: "begin_foundation",
  BEGIN_MODULE: "begin_Module",
  MODULE_COMPLETED: "module_completed",
  GO_TO_MY_STUDY: "go_to_my_study",
  BEGIN_MAPPING: "begin_mapping",
  CONTINUE_MAPPING: "continue_mapping",
  PERFORM_MAPPING: "perform_mapping",
  EMPTY: "empty",
};

export const DEFAULT_CURRENCY = {
  GBP: "GBP",
};

export const ORGANIZATION_USER_STATUS = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

export const TRIAL_MEMBERS = [
  { label: "All trial members", value: "allTrialMembers" },
  { label: "1 month trial members", value: "1MonthTrialMember" },
  { label: "3 month trial members", value: "3MonthTrialMember" },
];

export const ADMIN_PORTAL_TABLE_STATUS = {
  DRAFT: "draft",
  PUBLISHED: "publish",
};

export const USER_TYPE_LIST = [
  { label: "Tenant User", value: USER_TYPE.TENANT_USER },
  { label: "Tenant Admin", value: USER_TYPE.TENANT_ADMIN },
  { label: "Platform Admin", value: USER_TYPE.PLATFORM_ADMIN },
];

export const ADDRESS_TYPE = {
  INDIVIDUAL: "individual",
  ORGANIZATION: "organization",
};

export const AddressSearch = {
  MY_ADDRESS: "my",
  ORGANIZATION_ADDRESS: "organization",
};

export const PAYMENT_TYPE = [
  { label: "Subscription", value: "subscription" },
  { label: "Cart", value: "checkout" },
];

export const PAYMENT_OUTCOME = [
  { label: "Success", value: "success" },
  { label: "Failed", value: "fail" },
];

export const PAYMENT_TYPE_DATA = {
  SUBSCRIPTION: "subscription",
  CHECKOUT: "checkout",
};

export const DATE_RANGE_OPTIONS = [
  {
    label: "Last week",
    value: "LAST_WEEK",
  },
  {
    label: "Last month",
    value: "LAST_MONTH",
  },
  {
    label: "Last year",
    value: "LAST_YEAR",
  },
  {
    label: "Custom",
    value: "customDateRange",
  },
];

export const CONTENT_TYPE_LIBRARY = {
  VIDEO: "video",
  AUDIO: "audio",
  TEXT: "text",
};

export const MAPPING_SELF_TYPES = {
  POTENTIAL_SELF: "Potential",
  USUAL_SELF: "Usual",
  LIMITED_SELF: "Limited",
};

export const MAPPING_ANSWER_OPTIONS = {
  CONSTANTLY: "Constantly",
  REGULARLY: "Regularly",
  OCCASIONALLY: "Occasionaly",
};

export const MAPPING_QUESTIONS_TITLE = {
  PURPOSE: "Purpose",
  VISION: "Vision",
  VALUES: "Values",
  INSPIRATION: "Inspiration",
  MOTIVATION: "Motivation",
  MINDSET: "Mindset",
  FEELING: "Feeling",
  FORM: "Form",
  EXPERIENCE: "Experience",
  RELATING: "Relating",
  IDENTITY: "Identity",
  CHALLENGE_RESPONSIBILITY: "Challenge + Responsibility",
};

export const MAPPING_TOOL_WARNING = {
  POTENTIAL_SELF_ABOVE: "Cannot select above potential self",
  USUAL_SELF_ABOVE: "Cannot select above usual self",
  USUAL_SELF_AFTER: "Cannot select after usual self",
  LIMITED_SELF_AFTER: "Cannot select after limited self",
};

export const MAPPING_TOOL_URL = "mappingtool/index.html";

export const IL_WEBSITE_URL = "https://www.intelligent.life";

export const PRICING_PLAN_UPDATE_STATUS = {
  PLAN_UPDATING: "plan_updating",
  PLAN_UPDATED: "plan_updated",
};

export const PRICING_PLAN_STATUS = [
  { label: "Success", value: "success" },
  { label: "Failed", value: "failed" },
];

export const PRICING_PLAN_STATUS_TABLE = {
  SUCCESS: "success",
  FAIL: "failed",
};

export const API_RESPONSE_MSG = {
  SUCCESS: "Success",
};

export const PLAN_UPDATE_STATUS = {
  COMPLETED: "Completed",
  PENDING: "Pending",
};

export const STATUS_TYPE = [
  { label: "Draft", value: "draft" },
  { label: "Published", value: "publish" },
  { label: "All", value: "all" },
];

export const FOUNDATION_STATUS_TYPE = [
  { label: "Draft", value: "draft" },
  { label: "Published", value: "publish" },
  { label: "All", value: "all" },
];

export const IS_ACTIVE = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "All", value: "all" },
];

export const TOPIC_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  ALL: "all",
};

export const ADMIN_ANALYTICS_MEMBERSHIP_CATEGORIES = {
  ALL_MEMBERS: "All Members",
  PAID_MEMBERS: "Paid Members",
  FREE_MEMBERS: "Free Members",
  TRIAL_MEMBERS: "Trial Members",
};

export const ADMIN_ANALYTICS_MEMBERSHIP_TRIAL_VALUES = {
  ONE_MONTH_TRIAL: "One_Month_Trial",
  THREE_MONTHS_TRIAL: "Three_Months_Trial",
  TRIAL: "Trial",
  FREE: "Free",
  PAID: "Paid",
};

export const ADMIN_ANALYTICS_MEMBERSHIP_CATEGORIES_VALUES = {
  ALL_MEMBERS: "all_members",
  PAID_MEMBERS: "paid_members",
  FREE_MEMBERS: "free_members",
  TRIAL_MEMBERS: "trial_members",
};
