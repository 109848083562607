import loaderIcon from "assets/img/common/smallLoader.svg";
import centerPause from "assets/img/learning/vp_icon_CenterPause.svg";
import centerPlay from "assets/img/learning/vp_icon_CenterPlay.svg";
import maximize from "assets/img/learning/vp_icon_maximize.svg";
import minimize from "assets/img/learning/vp_icon_minimize.svg";
import pause from "assets/img/learning/vp_icon_pause.svg";
import play from "assets/img/learning/vp_icon_play.svg";
import Text from "components/Text";
import funnel from "assets/img/learning/vp_icon_vol_funnel.svg";
import highVolume from "assets/img/learning/vp_icon_vol_high.svg";
import lowVolume from "assets/img/learning/vp_icon_vol_low.svg";
import mute from "assets/img/learning/vp_icon_vol_mute.svg";
import { Slider } from "primereact/slider";
import { useRef, useState } from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player/lazy";
import screenfull from "screenfull";

type VideoCompleteConfig = {
  primaryButtonLabel?: string; // text
  primaryButtonOnClick?: () => void; // accept function
  primaryButtonIcon?: any; // image
  primaryButtonIconHover?: any; // image
  primaryLoader?: boolean;
  secondaryButtonLabel?: string; // text
  secondaryButtonOnClick?: () => void; // accept function
  secondaryButtonIcon?: any; // image
  onComplete?: () => void; // optional callback function
};

interface IVideoPlayerProps extends ReactPlayerProps {
  className?: string;
  onVideoComplete: VideoCompleteConfig;
  mediaSource: string;
  disableSeek?: boolean;
  showRemainingTime?: boolean;
  onFullscreen?: () => void;
  onVideoProgress?: () => void;
  audio?: boolean;
  thumbnail?: string;
}

interface VideoPlayerState {
  play: boolean;
  fullscreen: boolean;
  muted: boolean;
  played: number;
  volume: number;
  showEndScreen: boolean;
  hidePlayerControls: boolean;
}

interface OverlayProps {
  content?: VideoCompleteConfig;
  secondaryButtonHandler: () => void;
}

// -------------------------OverLay Component---------------------

function VideoPlayerOverlay(props: OverlayProps) {
  const { content, secondaryButtonHandler } = props;

  if (!content) return null;

  const onSecondaryButtonClick = () => {
    secondaryButtonHandler(); //updates the state of the player and restarts the media
    if (content.secondaryButtonOnClick) content.secondaryButtonOnClick();
  };

  const onMouseOverSecondaryBtn = () => {
    const primaryBtnImage = document.getElementById("primary-btn-icon");
    if (primaryBtnImage) {
      primaryBtnImage.setAttribute("src", content.primaryButtonIconHover);
    }
  };

  const onMouseOutSecondaryBtn = () => {
    const primaryBtnImage = document.getElementById("primary-btn-icon");
    if (primaryBtnImage) {
      primaryBtnImage.setAttribute("src", content.primaryButtonIcon);
    }
  };

  return (
    <div className="vp-overlay">
      <div className="vp-overlay-content">
        {content.primaryButtonLabel && (
          <button
            className="vp-overlay-content-primary"
            onClick={content.primaryButtonOnClick}
            onMouseOver={onMouseOverSecondaryBtn}
            onMouseOut={onMouseOutSecondaryBtn}
          >
            {content.primaryButtonLabel}
            <img
              src={content.primaryLoader ? loaderIcon : content.primaryButtonIcon}
              alt="icon"
              className={`${content.primaryLoader ? "animate-spin" : ""} btn-icon-primary`}
              id="primary-btn-icon"
            />
          </button>
        )}
        {content.primaryButtonLabel && content.secondaryButtonLabel ? (
          <div className="vp-overlay-or" style={{ pointerEvents: "none", color: "#E0E0E0" }}>
            or
          </div>
        ) : null}
        {content.secondaryButtonLabel && (
          <button className="vp-overlay-content-secondary" onClick={onSecondaryButtonClick}>
            <img src={content.secondaryButtonIcon} alt="icon" className="btn-icon-secondary" />
            <span className="text-xss">
              <Text label={content.secondaryButtonLabel} />
            </span>
          </button>
        )}
      </div>
    </div>
  );
}

let autoHideControlInSeconds = 0;

const format = (seconds: number) => {
  if (isNaN(seconds)) {
    return "00:00";
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

const VideoPlayer: React.FC<IVideoPlayerProps> = (props: IVideoPlayerProps) => {
  const {
    className,
    mediaSource,
    disableSeek,
    showRemainingTime,
    onVideoComplete,
    onFullscreen,
    onVideoProgress,
    audio,
    thumbnail,
    ...reactPlayerProps
  } = props;

  // component refs
  const playerContainerRef = useRef<HTMLDivElement>(null); // to toggle fullscreen
  const volSliderContainerRef = useRef<HTMLDivElement>(null); // to toggle visibility of volume slider
  const playerRef = useRef<ReactPlayer>(null); // to refer to ReactPlayer Instance
  const controlsRef = useRef<HTMLDivElement>(null); // to hide/show controls
  const videoSeekerRef = useRef<Slider>(null); // video player seekslider
  const volumeSeekerRef = useRef<Slider>(null); // volume seekslider

  // component internal state
  const [videoPlayerStatus, setVideoPlayerStatus] = useState<VideoPlayerState>({
    play: false,
    fullscreen: false,
    muted: false,
    played: 0,
    volume: 1,
    showEndScreen: false,
    hidePlayerControls: false,
  });

  const [timeDisplayFormat, setTimeDisplayFormat] = useState<string>("normal");

  //For thumbnail image overlay
  const [showPreview, setShowPreview] = useState<boolean>(
    thumbnail !== undefined && thumbnail !== ""
  );

  const currentTime: any = playerRef.current ? playerRef.current.getCurrentTime() : "00:00";
  const duration: any = playerRef.current ? playerRef.current.getDuration() : "00:00";

  const elapsedTime =
    timeDisplayFormat === "normal" ? format(currentTime) : `- ${format(duration - currentTime)}`;
  const totalDuration = format(duration);

  // ------------------event handlers--------------------------

  const handleProgress = (currentState: any) => {
    setVideoPlayerStatus({ ...videoPlayerStatus, ...currentState });

    if (controlsRef.current?.style.visibility === "visible") {
      autoHideControlInSeconds++;
    }

    if (autoHideControlInSeconds > 1) {
      controlsRef.current!.style.visibility = "hidden";
      autoHideControlInSeconds = 0;
    }

    if (props.onVideoProgress) {
      props.onVideoProgress();
    }
  };

  const handleSeekChange = (e: any) => {
    if (e.value / 100 !== 1) {
      setVideoPlayerStatus({
        ...videoPlayerStatus,
        played: parseFloat(e.value / 100 + ""),
      });
      playerRef.current!.seekTo(e.value / 100);
    } else {
      setVideoPlayerStatus({
        ...videoPlayerStatus,
        showEndScreen: true,
        hidePlayerControls: true,
      });
    }
  };

  const handleVolumeButton = () => {
    if (!videoPlayerStatus.muted) {
      setVideoPlayerStatus((prev) => {
        return {
          ...prev,
          muted: true,
          volume: 0,
        };
      });
    } else {
      setVideoPlayerStatus({
        ...videoPlayerStatus,
        muted: false,
        volume: 0.5,
      });
    }
  };

  const handleVolumeSeekChange = (e: any) => {
    setVideoPlayerStatus({
      ...videoPlayerStatus,
      volume: parseFloat(e.value / 100 + ""),
      muted: parseFloat(e.value) === 0 ? true : false,
    });
  };

  const handlePlayButton = () => {
    setVideoPlayerStatus({
      ...videoPlayerStatus,
      play: !videoPlayerStatus.play,
    });
  };

  const onFirstPlayBtnClick = () => {
    setVideoPlayerStatus({
      ...videoPlayerStatus,
      play: !videoPlayerStatus.play,
    });
    setShowPreview(false);
  };

  const handleChangeTimerFormat = () => {
    setTimeDisplayFormat(timeDisplayFormat === "normal" ? "remaining" : "normal");
  };

  const handleFullScreenButton = () => {
    screenfull.toggle(playerContainerRef.current ?? undefined).then(() => {
      setVideoPlayerStatus({
        ...videoPlayerStatus,
        fullscreen: !videoPlayerStatus.fullscreen,
      });
    });
  };

  const handleMediaEnd = () => {
    setVideoPlayerStatus({
      ...videoPlayerStatus,
      showEndScreen: true,
      hidePlayerControls: true,
    });
    if (props.onVideoComplete?.onComplete) {
      props.onVideoComplete.onComplete();
    }
  };

  //after video completion if secondary button clicked on end screen

  const secondaryButtonHandler = () => {
    setVideoPlayerStatus({
      ...videoPlayerStatus,
      played: 0,
      showEndScreen: false,
      play: false,
      hidePlayerControls: false,
    });
  };

  //exit full screen handler
  screenfull.onchange(() => {
    if (!screenfull.isFullscreen) {
      if (videoPlayerStatus.fullscreen === true) {
        setVideoPlayerStatus((prev) => {
          return {
            ...prev,
            play: prev.play,
            fullscreen: false,
          };
        });
      }
    }
  });

  //Without show preview
  const withoutPreview = () => {
    return videoPlayerStatus.hidePlayerControls ? null : (
      <div className="vp-controls-container" ref={controlsRef}>
        {videoPlayerStatus.play ? (
          <div
            className="vp-median-control-pause"
            onClick={() => {
              setVideoPlayerStatus({
                ...videoPlayerStatus,
                play: !videoPlayerStatus.play,
              });
            }}
          >
            <img src={centerPause} alt="play/pause feedback" />
          </div>
        ) : (
          <div
            className="vp-median-control"
            onClick={() => {
              setVideoPlayerStatus({
                ...videoPlayerStatus,
                play: !videoPlayerStatus.play,
              });
            }}
          >
            <img src={centerPlay} alt="play/pause feedback" />
          </div>
        )}

        <div className="vp-footer-container">
          <div className="vp-seekslider-container">
            <Slider
              id={"vp-seekbar"}
              ref={videoSeekerRef}
              value={videoPlayerStatus.played * 100}
              onChange={handleSeekChange}
              step={0.01}
              disabled={disableSeek}
            />
          </div>

          <div className="vp-controls">
            <div className="vp-left-controls">
              <div className="vp-btn-play" onClick={handlePlayButton}>
                <img
                  src={videoPlayerStatus.play ? pause : play}
                  alt="play/pause"
                  id="vp-play-icon"
                />
              </div>
              {showRemainingTime ? (
                <div className="vp-timer" onClick={handleChangeTimerFormat}>
                  {elapsedTime} / {totalDuration}
                </div>
              ) : null}
            </div>

            <div className="vp-right-controls">
              <div
                className="vp-btn-vol"
                onClick={handleVolumeButton}
                onMouseEnter={() => {
                  volSliderContainerRef.current!.style.visibility = "visible";
                }}
                onMouseLeave={() => {
                  volSliderContainerRef.current!.style.visibility = "hidden";
                }}
              >
                <img id="vp-vol-funnel" src={funnel} alt="volume control" />
                <div className="vp-vol-feedback">
                  <img
                    src={
                      videoPlayerStatus.muted
                        ? mute
                        : videoPlayerStatus.volume <= 0.5
                        ? lowVolume
                        : highVolume
                    }
                    alt="volume control"
                  />
                </div>

                <div
                  className="vp-vol-slider-wrapper"
                  ref={volSliderContainerRef}
                  style={{ visibility: "hidden" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="vp-vol-slider-container">
                    <Slider
                      id="vp-vol-slider"
                      ref={volumeSeekerRef}
                      value={videoPlayerStatus.volume * 100}
                      onChange={handleVolumeSeekChange}
                      orientation="vertical"
                    />
                  </div>
                </div>
              </div>

              {!audio && (
                <div className="vp-btn-fs" onClick={handleFullScreenButton}>
                  <img
                    id="vp-fullscreen-icon"
                    src={videoPlayerStatus.fullscreen ? minimize : maximize}
                    alt="minimize/maximize screen"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`vp-wrapper ${className ?? ""}`}
      ref={playerContainerRef}
      onMouseMove={() => {
        if (controlsRef.current) {
          controlsRef.current.style.visibility = "visible";
        }
        autoHideControlInSeconds = 0;
      }}
    >
      <ReactPlayer
        width="100%"
        height="100%"
        ref={playerRef}
        url={mediaSource}
        muted={videoPlayerStatus.muted}
        playing={videoPlayerStatus.play}
        volume={videoPlayerStatus.volume}
        loop={false}
        progressInterval={1000}
        onProgress={handleProgress}
        onEnded={handleMediaEnd}
        {...reactPlayerProps}
      />

      {/* -----------------------video player controls------------------- */}

      {showPreview ? (
        <div className="w-full h-full absolute top-0">
          <div
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pl-2.5 pr-2 py-2.06 sm:pl-5 sm:pr-4 sm:py-4.5 bg-white opacity-70 rounded-full cursor-pointer"
            onClick={onFirstPlayBtnClick}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onFirstPlayBtnClick();
              }
            }}
            tabIndex={0}
          >
            <img src={centerPlay} alt="play/pause feedback" />
          </div>

          <div className="w-full h-full">
            <img className="w-full h-full object-cover" src={thumbnail} alt="Thumbnail" />
          </div>
        </div>
      ) : (
        withoutPreview()
      )}

      {videoPlayerStatus.showEndScreen ? (
        <VideoPlayerOverlay
          content={onVideoComplete}
          secondaryButtonHandler={secondaryButtonHandler}
        />
      ) : null}
    </div>
  );
};
export default VideoPlayer;
