export const getEnvVariableValue = (key: any) => {
  if (!key) throw new Error("Please pass the key to get value");
  if (window.env && window.env[key]) return window.env[key];
  return process.env[key];
};

/**
 *
 * @returns oject which contains device information
 */
// export const getDeviceInformation = () => {
//   return {
//     isMobile: navigator.userAgentData.mobile,
//     platform: navigator.userAgentData.platform,
//   };
// };

/**
 *
 * @param {any} data
 * @returns encoded string
 */
export const encode = (data: string) => {
  return btoa(data);
};

/**
 *
 * @param {string} data // encrypted data
 * @returns decoded string
 */
export const decode = (data: string) => {
  return atob(data);
};

/**
 *
 * @param {function} onSuccessCallBack
 * @param {function} onErrorCallBack
 */
export const getGeoLocation = (onSuccessCallBack: any, onErrorCallBack: any) => {
  if (!("geolocation" in navigator)) {
    onErrorCallBack({
      code: 0,
      message: "Geolocation not supported",
    });
  }

  navigator.geolocation.getCurrentPosition(onSuccessCallBack, onErrorCallBack);
};

export const getLocalLanguage = () => {
  var localStorageObject = JSON.parse(getLocalStorage("defaultLocale"));
  return localStorageObject.defaultLanguage;
};

/**
 *
 * @param {string} data
 */
export const copyData = (data: any) => {
  navigator.clipboard
    .writeText(data)
    .then(() => {
      console.log(">> Copied!!");
    })
    .catch(() => {
      console.log(">> Fail to copy");
    });
};
/**
 *
 * @param {string} key
 * read data from local storage
 */
export const getLocalStorage = (key: string) => {
  return localStorage.getItem(key) ?? "";
};
/**
 *
 * @param {string} key
 * @param {string | object} value
 */
export const setLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};
export const removeFromStorage = (key: string) => {
  return localStorage.removeItem(key);
};

/**
 *
 * @returns boolean value of the network status
 */
export const isOnline = () => {
  return navigator.onLine;
};

// multiple date formats
function addZero(i: number | string) {
  if (parseFloat(i.toString()) < 10) {
    i = "0" + i;
  }
  return i;
}
/**
 *
 * @param {integer} month
 * @returns previous date based on parameter passed or it default retrun on month back date
 */
export const getPrevMonthDate = (month = 1) => {
  let prevDate = new Date();
  prevDate.setMonth(prevDate.getMonth() - month);
  return prevDate;
};
/**
 *
 * @param {Date | string} date
 * @returns fromdated Time With AM / PM
 */
export const dateFormatAMPM = (dateInString: string) => {
  let date = new Date(dateInString);
  let hours = date.getHours();
  let minutes: number | string = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};
/**
 *
 * @param {string} dateToFormat
 * @returns  formated string from date object
 */
export const formattedDate = (dateToFormat: any) => {
  if (!dateToFormat) return "";
  let myDate = new Date(dateToFormat);
  let month = addZero(myDate.getMonth() + 1);
  let date = addZero(myDate.getDate());
  let year = myDate.getFullYear();
  let hh = addZero(myDate.getHours());
  let mm = addZero(myDate.getMinutes());
  let ss = addZero(myDate.getSeconds());
  return date + "/" + month + "/" + year + " " + hh + ":" + mm + ":" + ss;
};

export const formattedDateForTable = (date: any) => {
  const newDate = new Date(date);
  const formattedDate = newDate.toLocaleDateString("en-GB").replace(/\//g, " / ");
  return formattedDate;
};

export const formatDateForMapping = (date: string) => {
  const newDate = new Date(date);
  const day = newDate.getDate().toString().padStart(2, "0");
  const month = newDate.toLocaleString("default", { month: "short" });
  const year = newDate.getFullYear();
  return `${day} ${month} ${year}`;
};

export const formatDuration = (seconds: number) => {
  if (!seconds) return "--";
  
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  let result = "";

  if (hrs > 0) {
    result += hrs + " hr ";
    result += mins < 10 ? "0" + mins + " min" : mins + " min";
  } else if (mins > 0) {
    result += mins + " min";
    if (secs > 0) result += " " + secs + " sec";
  } else {
    result = secs + " sec";
  }

  return result;
};


export const convertDateFormat = (inputDate: string) => {
  const date = new Date(inputDate);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export const convertStartDateFormat = (inputDate: string) => {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  // Set the time to 00:00:00 for the given date
  date.setHours(0, 0, 0, 0);

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  // Construct the formatted date string
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export function formatDate(date: Date): string {
  const day = date.getDate();
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  const ordinalSuffix = (day: number) => {
    if (day > 3 && day < 21) return 'th'; 
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return `${day}${ordinalSuffix(day)} ${month} ${year}`;
}

export const convertEndDateFormat = (inputDate: string) => {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  // Set the time to the maximum for the given date
  date.setHours(23, 59, 59, 999);

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  // Construct the formatted date string
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export const capitalizeFirstLetters = (inputString: string) => {
  return inputString.replace(/\b\w/g, (char) => char.toUpperCase());
};

// ---- Standard Validator ----
/**
 * Standard Validator functions to be used with simple-react-validator package
 * @returns validation object
 */
export const getStandardValidator = () => {
  return {
    mandatory: {
      message: "Mandatory",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (val.toString()) {
          return true;
        } else {
          return false;
        }
      },
      required: true, // optional
    },
    validDuration: {
      message: "Mandatory",
      rule: (val: any, params?: any, validator?: any): boolean => {
        let validation = false;
        if (typeof val !== "string") {
          return !validation;
        }
        if (!val.toString()) {
          validation = true;
        }
        const [minutes, seconds] = val.split(":");

        if (parseInt(seconds) === 0) {
          if (isNaN(parseInt(minutes)) || parseInt(minutes) === 0 || minutes === undefined) {
            validation = true;
          }
        }
        return !validation;
      },
      required: true,
    },
    onlyPositive: {
      message: "Negative values are not allowed",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (val) {
          let isPositive = Math.sign(Number(val));
          if (isPositive < 0) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      },
    },
    onlyInteger: {
      message: "Decimals not allowed",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (val) {
          let numericValue = Number(val);
          if (!(numericValue || numericValue === 0) && !isNaN(numericValue)) {
            return false;
          }
          let splitValue = val.toString().split(".");
          if (splitValue) {
            if (splitValue.length > 1) {
              return false;
            }
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      },
      messageReplace: (message: string, params?: any) => {
        // return message.replace(":type", "AA");
      },
    },
    max: {
      message: ":type",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (params[1] === "num") {
          if (parseFloat(val) > parseFloat(params[0])) {
            return false;
          } else {
            return true;
          }
        } else {
          if (val.length > parseFloat(params[0])) {
            return false;
          } else {
            return true;
          }
        }
      },
      messageReplace: (message: string, params?: any) => {
        if (params[1] === "num") {
          return message.replace(":type", `Max value is ${params[0]}`);
        } else {
          return message.replace(":type", `Max length ${params[0]} chars`);
        }
      },
    },
    min: {
      message: ":type",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (params[1] === "num") {
          if (parseFloat(val) < parseFloat(params[0])) {
            return false;
          } else {
            return true;
          }
        } else {
          if (val.length < parseFloat(params[0])) {
            return false;
          } else {
            return true;
          }
        }
      },
      messageReplace: (message: string, params?: any) => {
        if (params[1] === "num") {
          return message.replace(":type", `Min value is ${params[0]}`);
        } else {
          return message.replace(":type", `Min length ${params[0]} chars`);
        }
      },
    },
    maxDecimal: {
      message: "Enter only Four Decimal values",
      rule: (val: any, params?: any, validator?: any): boolean => {
        const re = new RegExp(`^\\d+\\.?\\d{0,${params[0]}}$`);

        if (re.test(val)) {
          return true;
        } else {
          return false;
        }
      },
    },
    // ------- testing ---------- //
    passwordStatus: {
      message: "Weak password",
      rule: (val: any, params?: any, validator?: any): boolean => {
        if (val.toString() === "Weak") {
          return false;
        } else {
          return true;
        }
      },
    },
    phoneCustom: {
      message: "Number should be valid",
      rule: (val: any, params?: any, validator?: any): boolean => {
        const regex = /^[0-9-\(\) ]+$/;
        if (regex.test(val)) {
          return true;
        } else {
          return false;
        }
      },
    },
  };
};

/**
 * Message Replacement
 * @returns
 */
export const messageReplace = () => {
  return {
    messages: {
      alpha_num: "Special chars are not allowed",
      alpha_num_dash: "Special chars except dash (-) are not allowed",
      numeric: "Only numbers are allowed",
      phone: "It must be a valid contact number",
      // required: "Mandatory",
    },
  };
};

/**
 * parse the error message from object
 * @param {object} errorMessageObj
 * @returns formatted error string
 */
export const parseStandardAPIErrorMessage = (errorMessageObj: any) => {
  let prepareMessage = "";
  const errorDetails = errorMessageObj?.response?.data?.details;
  let errorMessage = errorMessageObj?.response?.data?.message;

  const codePattern = /^C\d+:\s*/;

  if (errorDetails) {
    if (errorDetails.length > 1) {
      for (let i = 0; i < errorDetails.length; i++) {
        const element = errorDetails[i];
        prepareMessage = prepareMessage + element + "\n";
      }
    } else {
      prepareMessage = errorDetails.at(0);
    }
    return prepareMessage;
  }
  if (Array.isArray(errorMessage) && errorMessage.length > 0) {
    return errorMessage.join(" ").replace(codePattern, "");
  } else if (errorMessage) {
    return errorMessage;
  }
  if (!errorMessage || !errorDetails) {
    return `Something Went Wrong!!!`;
  }
};

// function to parse the error message from object where error message is array of string iterate that array each string element has : in it split the error message from : and return the second element of the array into a string
export const parseStandardAPIErrorMessageArray = (errorMessageObj: any) => {
  let prepareMessage = "";
  const errorDetails = errorMessageObj?.response?.data?.details;
  const errorMessage = errorMessageObj?.response?.data?.message;

  if (errorMessage) {
    if (errorMessage.length > 0) {
      for (let i = 0; i < errorMessage.length; i++) {
        const element = errorMessage[i];
        const splitMessage = element.split(":");
        prepareMessage = prepareMessage + splitMessage[1] + "\n";
      }
    } else {
      prepareMessage = errorMessage.at(0);
    }
    return prepareMessage;
  }
  if (errorMessageObj.message) {
    return errorMessageObj.message;
  }
  if (!errorMessage || !errorDetails) {
    return `Something Went Wrong!!!`;
  }
  return `Something Went Wrong!!!`;
};

//----------Password verification------------//

export const verifyCharacterLimit = (string: string, lowerLimit: number) => {
  if (!string) return false;
  if (string.length >= lowerLimit) {
    return true;
  }
  return false;
};

export const verifyNumber = (string: string): boolean => {
  let pattern1 = /[0-9]/;
  if (!string) return false;
  if (string.match(pattern1)) {
    return true;
  }
  return false;
};

export const verifyLowerCase = (string: string): boolean => {
  let pattern2 = /[a-z]/;
  if (!string) return false;
  if (string.match(pattern2)) {
    return true;
  } else {
    return false;
  }
};

export const verifyUpperCase = (string: string): boolean => {
  let pattern3 = /[A-Z]/;
  if (!string) return false;
  if (string.match(pattern3)) {
    return true;
  } else {
    return false;
  }
};

export const validateGetAPIResponse = (res: any) => {
  if (res.status === 200) {
    return true;
  }

  return false;
};

export const validatePostAPIResponse = (res: any) => {
  if (res.status === 201) {
    return true;
  }
  return false;
};

export const validatePutAPIResponse = (res: any) => {
  if (res.status === 200) {
    return true;
  }
  return false;
};

export function zeroWidthTrim(stringToTrim: string) {
  const ZERO_WIDTH_SPACES_REGEX = /([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g;

  console.log("stringToTrim = " + stringToTrim);

  const trimmedString = stringToTrim.replace(ZERO_WIDTH_SPACES_REGEX, "");

  console.log("trimmedString = " + trimmedString);

  return trimmedString;
}

export function appendByComma(label: string, isLastItem?: boolean) {
  if (!label || (typeof label === "string" && label.trim() == "")) {
    return "";
  }
  let value = label;
  if (label.charAt(label.length - 1) === ",") {
    value = label.slice(0, label.length - 2);
  }
  return isLastItem ? `${value}` : `${value}, `;
}
